<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card class="cardClassDetailForm" title="작업결과 상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="param.workPlanId && editable"
                  label="작업지시서 출력"
                  icon="print"
                  @btnClicked="printInstruct" />
                <c-btn
                  v-if="editable&&!disabled"
                  :url="updateUrl"
                  :isSubmit="isSave"
                  :param="data"
                  mappingType="PUT"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :required="true"
                  :editable="editable&&!disabled"
                  label="지시일"
                  name="instructDate"
                  v-model="data.instructDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable&&!disabled"
                  :data="data"
                  type="user"
                  label="담당자"
                  name="instructUserId"
                  v-model="data.instructUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-textarea
                  :editable="editable&&!disabled"
                  label="지시내용(기록)"
                  :rows="4"
                  name="instructContent"
                  v-model="data.instructContent">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="gridWork"
          title="작업계획 목록"
          :columns="gridWork.columns"
          :data="data.workList"
          :gridHeight="gridWork.height"
          :editable="editable&&!disabled"
          :isDense="true"
          bgHeaderClass="bg-grid-header-main"
          :hideBottom="true"
          :isExcelDown="false"
          :gridHeightAuto="true"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;"> 
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable && !disabled"
          :isPhotoView="true"
          label="작업지시 사진">
        </c-upload>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-instruct',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
      }),
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      printUrl: '',
      data: {
        instructDate: '', 
        instructUserId: '', 
        instructContent: '', 
        workPlanId: '',  // 작업계획 일련번호
      },
      gridWork: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workPlanWorkName',
            field: 'workPlanWorkName',
            label: '작업명',
            style: 'width:180px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workDtsStr',
            field: 'workDtsStr',
            label: '작업기간',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '전달사항',
            style: 'width:300px',
            rows: 2,
            type: 'textarea',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '336px',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_INSTRUCT',
        taskKey: '',
      },
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      this.$emit('disabledSet', this.disabledObj.disabled)
      return this.disabledObj.disabled;
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.printUrl = selectConfig.wod.workorder.instruct.print.url;
      this.detailUrl = selectConfig.wod.workorder.instruct.get.url;
      this.updateUrl = transactionConfig.wod.workorder.instruct.update.url;

      if (this.param.woWorkPlanStepCd !== 'WWPSC00001') {
        this.disabledObj.disabled = true;
      }
      this.getDetail();
      this.$set(this.attachInfo, 'taskKey', this.param.workPlanId)
    },
    getDetail() {
      if (this.param.workPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.workPlanId = _result.data.workPlanId;
      this.getDetail();
    },
  
    // [E]] 결재관련 
    // 출력물 다운로드 샘플
    printInstruct() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        workPlanId: this.param.workPlanId,
      }
      this.$http.request(
        _result => {
          let fileOrgNm = '작업지시서_' + this.data.instructDate + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>